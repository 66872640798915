import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Hub from "./hub"

const filterData = (data, articletype) => data.allPrismicArticle.nodes.filter((n) => n.data.articletype === articletype)

const SailingTips = ({ data }) => {
  const hub = filterData(data, "Sailing Tips Hub")[0].data
  const guides = filterData(data, "Sailing Tips Guide")
  const groups = [{ label: "Sailing Tips", guides }]
  return <Hub hub={hub} groups={groups} />
}

export const query = graphql`
  query SailingTipsHubQuery {
    allPrismicArticle(
      filter: { data: { articletype: { in: ["Sailing Tips Hub", "Sailing Tips Guide"] } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        data {
          country
          location
          title
          previewtext
          articletype
          author {
            document {
              ... on PrismicAuthor {
                data {
                  name
                  avatar {
                    fluid {
                      __typename
                    }
                  }
                }
                first_publication_date
                uid
                type
              }
            }
          }
          heroimage {
            gatsbyImageData(height: 700)
          }
          body {
            ... on PrismicArticleDataBodyRichText {
              id
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
            ... on PrismicArticleDataBodyImage {
              id
              slice_type
              primary {
                caption
                image {
                  gatsbyImageData(width: 1280, height: 500)
                }
              }
            }
          }
        }
        first_publication_date(formatString: "DD MMMM YYYY")
        uid
        type
      }
    }
  }
`

SailingTips.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default SailingTips
